import React from "react"
import CardSeller from "../../components/cardSeller"
import IntegrationCardsContainer from "../../components/integrationCardsContainer"
import Button from "../../components/button"
import Head from "../../components/head"
import { graphql } from "gatsby"
import UseCaseHeader from "../../components/new/useCaseHeader/useCaseHeader"
import Container from "../../components/new/container/container"
import Layout from "../../components/new/layout/layout"

export const query = graphql`
query {
	allContentfulSeller {
	  edges {
		node {
			slug
			title
			logo
			website
		}
	  }
	}
  }

`

const Sellers = (props) => {
	return (
        <Layout navbar="transparent">
        <Head title={''} description={''}/>
        <UseCaseHeader title={'Sellers'} subtitle={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae eleifend lorem.'} image={'https://assets.onport.com/assets/images/bg-1-m.svg'}/>
                <IntegrationCardsContainer>
                  {props.data.allContentfulSeller.edges.sort((a, b) => a.node.title.localeCompare(b.node.title)).map((edge) => {
                      return(
                        <CardSeller content={{
                          title: edge.node.title,
                          slug: edge.node.slug,
                          plans: '',
                          summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vitae eleifend lorem.',
                          category: 'seller',
                          image: edge.node.logo }}/>
                      )})
                  }
                </IntegrationCardsContainer>
		</Layout>
    )
}


export default Sellers
