import React from "react"
import Navbar  from "../../navbar"
import Footer  from "../footer/footer"
import Banner  from "../banner/banner"

const Layout = ({children, navbar, position}) => {
  return (
      <div className="layout">
        <Banner />
        <Navbar style={{classes: navbar || 'darkblue', position: position}}/>
        <div className="content">
          {children}
        </div>
        <Footer />
      </div>
    )
}

export default Layout
